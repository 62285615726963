import { HttpClient } from "../HttpClient";
/**
 * * Get Users
 */
export const GetUsers = async (id) => {
	try {
		const { data } = await HttpClient.get(`api/users/${id}`);
		return await Promise.resolve(data);
	} catch (err) {
		return Promise.reject(err);
	}
};
