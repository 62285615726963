import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { GetUsers } from "../services/Users/GetUsers";
import { GetAssetLabels } from "../services/AssetLabels/GetAssetLabels";

export const useUsersStore = defineStore("users", () => {
  const users = ref({
    email: "",
    firstName: "",
    id: "",
    lastName: "",
    userTypeId: "",
    isDistributor: false,
    googleSheetLink: null,
    partnerPercentage: "",
    sheetName: null,
  });
  const isAdmin = computed(() => {
    return users.value.userTypeId == 1;
  });
  const isUser = computed(() => {
    return users.value.userTypeId != 1;
  });
  const assetLabels = ref([]);
  const assetLabelDataTable = ref({
    columns: [{ label: "Name", field: "name" }],
    rows: [],
  });
  const getUsers = (payload) => {
    GetUsers(payload?.UserId).then((response) => {
      users.value = response;
      setIsDistributor(users.value.userTypeId);
    });
  };
  const setIsDistributor = (id) => {
    if (id == 3) users.value.isDistributor = true;
  };
  const getAssetLabels = (payload) => {
    GetAssetLabels(payload?.UserId).then((response) => {
      setSelectedAssetLabels(response.data);
      setDropdownValues();
    });
  };
  const assignedDashboard = ref(1);
  const setSelectedAssetLabels = (selectedArray) => {
    const newArray = selectedArray.map((item) => {
      return {
        ...item,
        selected: true,
        assignedDashboard:
          item.assignedDashboard != null ? item.assignedDashboard : null,
      };
    });
    assetLabels.value = newArray;
  };

  const setDropdownValues = () => {
    assetLabelDataTable.value.rows = assetLabelDataTable.value.rows.map(
      (item) => {
        const item2 = assetLabels.value.find((i2) => {
          if (i2.id == item.id) return i2;
        });
        return item2 ? { ...item, ...item2 } : item;
      }
    );
  };
  return {
    getUsers,
    users,
    getAssetLabels,
    assetLabels,
    assignedDashboard,
    setDropdownValues,
    assetLabelDataTable,
    isAdmin,
    isUser,
  };
});
