import { HttpClient } from "../HttpClient";
/**
 * * Get Asset Label
 */
export const GetAssetLabels = async (id) => {
	try {
		const { data } = await HttpClient.get("api/assetLabels/List", {
			params: {
				UserId: id,
			},
		});
		return await Promise.resolve(data);
	} catch (err) {
		return Promise.reject(err);
	}
};
